//In src/components/sub-form.component.js
import React, { useState } from 'react';

const SubscriptionForm = (props) => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');

    const FORM_URL = props.formUrl;

    const handleSubmit = async (e) => {
        const data = new FormData(e.target);
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            setEmail('');
            const json = await response.json();
            if (json.status === 'success') {
                setStatus('SUCCESS');
                return;
            }
        } catch (err) {
            setStatus('ERROR');
            console.log(err);
        }
    };

    const handleInputChange = event => {
        const {value} = event.target;
        setEmail(value);
    }

    return (
        <div>
            {status === 'SUCCESS' && <p>Please go confirm your subscription!</p>}
            {status === 'ERROR' && <p>Oops, Something went wrong! try again.</p>}

            <form className="mt-3 sm:flex" action={FORM_URL} method="post" onSubmit={handleSubmit}>
                <label for="email" className="sr-only">
                    Email
                </label>
                <input
                    className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                    type="email"
                    aria-label="Enter your email"
                    //The name attribute should be the same as on convertkit  selected form.
                    name="email_address"
                    placeholder="Your email address"
                    onChange={handleInputChange}
                    value={email}
                    required
                />
                <button
                    type="submit"
                    className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                    {props.buttonText}
                </button>
            </form>
        </div>
    );
};

export default SubscriptionForm
